
/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0; padding: 0; white-space: normal; *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0; vertical-align: baseline; *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle; color: $black;
}


label, .label {
  display: block; margin-bottom: 8px;
  user-select: none;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;  cursor: pointer; *overflow: visible; border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

.checkbox {
  position: relative;
}

.checkbox--toggle {
  input[type="checkbox"] {
    &:focus,
    &:active {
      + .label,
      + label {
        &:before {
          border: 1px solid gray(700);
        }
      }
    }

    &:disabled {
      + .label,
      + label {
        cursor: not-allowed;

        &:before {
          opacity: 0.25;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    + .label,
    + label {
      padding-left: 55px;
      user-select: none;

      &:focus,
      &:active {
        outline: none;
      }

      &:before,
      &:after {
        position: absolute;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 40px;
        height: 20px;
        background: $grayDark;
        border-radius: 20px;
        left: 0;
        border: 0;
        top: 0;
        transition: background-color $transitionDefault;
      }

      &:after {
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $white;
        top: 2px;
        z-index: 1;
        left: 2px;
        opacity: 1;
        transform: none;
        transition: all $transitionDefault;
        border-width: 0;
        border-color: gray(500);
      }
    }

    &:checked {
      + .label,
      + label {
        &:before {
          background: $black;
        }
        &:after {
          left: 22px;
        }
      }
    }
  }
}

.checkbox__description {
  margin-left: 55px;
  display: block;
  color: gray(500);
  font-size: rem(14px);
}

input[type="text"], input[type="search"], input[type="password"], input[type="number"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 11px 20px; margin-bottom: 14px; display: block;
  background: #fff; height: 48px; line-height: inherit; width: 100%; border: 1px solid $lightBlue;
  &:focus { outline: 0; border-color: darken($lightBlue, 10%); }
  border-radius: 4px;
}

input[type="number"] {
  max-width: 130px;
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto; vertical-align: top; resize: vertical; width: 100%; height: 185px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; width: auto; height: auto; padding: 0;
  margin: 3px 0; *margin-top: 0; *height: 13px; *width: 13px; line-height: normal; border: none;
}

input[type="file"] {
  background-color: #fff; padding: initial; border: initial; line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Buttons
button[type="button"]:not(.button) {
  background-color: transparent;
}

input[type="reset"], input[type="submit"], .button {
  float: left; display: block; clear: both;
  transition: $transitionDefault;
  outline: 0; line-height: 1.2em;
  padding: 13px 32px; margin: 10px 0 0; background-color: $accent;
  text-decoration: none; color: #fff;
  font-weight: 400;
  font-size: 14px;
  border-radius: 2px;
  user-select: none;

  @media #{$medium} {
    padding: 18px 42px;
  }

  i { margin: -5px 6px 0 5px; }

  &:hover, &:focus, &:active {
    background-color: $accentHover;
    color: $white;
  }

  &:active {
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

.button--smaller {
  padding: 13px 28px;

  @media #{$medium} {
    padding: 15px 28px;
  }
}

.button--inline {
  float: none;
  display: inline-block;
}

.button--simplistic {
  border: 1px solid $grayMiddle;
  background-color: $white;
  padding-left: 75px;
  padding-right: 75px;
  color: $black;

  &:hover, &:focus, &:active {
    background-color: $grayLight;
    color: $black;
  }
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0; *outline: inherit;
  }
}

::placeholder { color: $gray; font-style: italic; }

// Hide clear text field ie 10+
::-ms-clear { display: none; }

// Remove the rounded corners
input[type="search"] { -webkit-appearance: none; }

// Hide the cancel button
::-webkit-search-cancel-button { -webkit-appearance: none; }

// Hide the magnifying glass
::-webkit-search-results-button { -webkit-appearance: none; }

::-webkit-textfield-decoration-container { }

// Hide number input spinner
::-webkit-inner-spin-button { -webkit-appearance: none; }
::-webkit-outer-spin-button { -webkit-appearance: none; }

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button { }

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;

    li {
      list-style: none; background: none; list-style-image: none;
      margin: 0 0 10px; float: left; width: 100%; padding: 0 0 0 20px;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half { width: 50%; }
        &.one-fourth { width: 25%; }
      }
    }
  }
}

.buttons {
  @include clearfix;
  button, input[type="reset"], input[type="submit"], .button, a.button {
    float: left; clear: none; margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none; clear: none; display: inline-block;
  }
}

// Recaptcha
.grecaptcha-badge { display: none; }

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: $black;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: $black;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


/* Hide the browser's default checkbox */
input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
  margin: 0;
  visibility: hidden;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  pointer-events: none;
  border: 1px solid $grayishBlue;
  background-color: $white;
  border-radius: 2px;
  margin: 0;
}

/* On mouse-over, add a grey background color */
.input[type="checkbox"]:hover ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
input[type="checkbox"]:checked ~ .checkmark {
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 13px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @extend .icon-checkmark:before;
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-45%);
}


.input-group {
  display: flex;
  align-items: center;

  input {
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -2px;
    padding-left: 0;
  }

  button {
    margin: 0;
    padding: 16px;
    padding-bottom: 15px;
    height: 48px;
  }
}
