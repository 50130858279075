/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: 0;

    [dir="rtl"] & {
        float: right;
    }
    img {
        outline: 0;
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.spotlight-slider {
  padding-bottom: $gutter * 3;
  margin-left: -$gutter / 2;
  margin-right: -$gutter / 2;

  .slick-list {
    overflow: visible;
  }

  .slick-arrow {
    border-color: transparent;
    opacity: 1;
    width: 34px;
    height: 34px;
    border-radius: 100px;
    z-index: 10;
    top: auto;
    bottom: 0;
    background: #fff no-repeat center center;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  .slick-prev {
    left: auto;
    right: 50%;
    margin-right: 1.25rem;
    background-image: url("../images/slick-arrow-left.svg");
  }

  .slick-next {
    right: auto;
    left: 50%;
    margin-left: 1.25rem;
    background-image: url("../images/slick-arrow-right.svg");
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    color: transparent;
    padding: 0;
    border: 0;
    outline: 0;
  }

  .spotlight__card {
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;

    .spotlight__link {
      display: block;
      padding-bottom: 88.2352941176%;
      position: relative;
      color: $white;
      // border-radius: 30px;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 0;
        transition: $transitionDefault;
      }
    }

    .cover-image {
      background: transparent no-repeat 50% 50%;
      background-size: cover;

      img {
        margin: -1px;
        padding: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        clip: rect(0,0,0,0);
        position: absolute;
      }
    }

    .spotlight__wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 1.125rem 1.5625rem 1.375rem;
    }

    h3 {
      // @include default-font;
      margin: 0;
      font-size: 3.5rem;
      color: $white;
      font-weight: 600;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    &:hover {
      .spotlight__link::before {
        opacity: 1;
      }

      h3 {
        color: $black;
      }

      p {
        color: $black;
        white-space: normal;
        overflow: visible;
        text-overflow: unset;
      }
    }
  }
}
