
/*============================================================================
  TABLES
==============================================================================*/

table {
  width: 100%; padding: 0; border-collapse: separate; border-spacing: 0;
  empty-cells: show; margin: 0 0 1.5em;

  th, td {
    padding: 11px 6px; text-align: left; vertical-align: middle;
    border-bottom: 1px solid $grayLight; line-height: 1.5em;
  }

  th {
    font-weight: bold; border-bottom: 1px solid $grayLight;
  }
}

.rte {
  table {
    width: 100%;
    font-size: 12px;
    p {
      margin-bottom: 0;
    }
    thead {

    }
    tbody {
      th {
        border-bottom: 1px solid $grayLight;
        text-align: left;
        min-width: 120px;
      }
      td {
        text-align: center;
      }
    }
    @media #{$medium} {
      font-size: 14px;
    }
  }
}
