.iw-container {
  position: relative !important;

  @media #{$medium} {
    max-width: 286px !important;
    min-width: 250px !important;
  }

  .visual {
    padding-top: 46.8%;
  }
}

.gm-style, .gm-style-iw {
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

.gm-style-iw {
  & > div:first-of-type {
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;
    max-height: none !important;
  }
  + button {
    background: $white !important;
  }
}

.iw-content__inner {
  padding: 18px 18px 30px 18px;
  color: #767676;
  font-weight: 400;
}

.iw-content__title {
  color: $accent;
  font-size: 14px;
  font-weight: 600;
}
