// Button group
.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$gutter;
  margin-left: -$gutter;

  .button,
  a {
    margin-bottom: $gutter;
    margin-left: $gutter;
  }
}

.button-group--gutter-0 {
  margin-left: 0;
  margin-bottom: 0;

  .button,
  a {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.button-group--right {
  justify-content: flex-end;
}

.button-group--center {
  justify-content: center;
}

.button-group--align-center {
  align-items: center;
}
