/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";
@import "inc/maps";
@import "inc/buttons";

// Plugins
@import "plugins/validation-engine";
@import "plugins/fancybox";
@import "plugins/slick";
@import "plugins/simplebar";
@import "plugins/form";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;

  &.js-fullscreen-overlay {
    overflow: hidden;
  }

  &.js-opened-filters {
    overflow: hidden;

    @media #{$large} {
      overflow: auto;
    }
  }

  &.has-open-nav {
    @media #{$maxMedium} {
      overflow: hidden;
    }
  }
}

body {
  @include default-font;
  color: $black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);

  &.travel-detail {
    @media #{$maxSmall} {
      padding-bottom: 60px;
    }
  }

  &.journeys,
  &.excursions {
    .main {
      padding-top: 115px;

      @media #{$medium} {
        padding-top: 145px;
        padding-bottom: 0;
      }
    }

    @media #{$medium} {
      .page-footer {
        display: none;
      }
    }
  }

  &.privacy,
  &.terms-and-conditions {
    h2 {
      font-size: 20px;

      @media #{$medium} {
        font-size: 20px;
      }

      @media #{$large} {
        font-size: 24px;
      }

      @media #{$massive} {
        font-size: 28px;
      }
    }

    h3 {
      @extend h4;
    }
  }
}

.page-wrap {
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media #{$medium} {
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media #{$larger} {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.container--smaller {
  max-width: 1240px;
}

.container--large {
  max-width: 1500px;
  padding: 0;
}

.container--travel-detail-scheme {
  padding: 0 20px;
  @media #{$medium} {
    padding: 0 100px;
  }

  @media #{$large} {
    padding-left: 80px;
    padding-right: 100px;
  }

  @media #{$larger} {
    padding-left: 100px;
    padding-right: 100px;
  }
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: 0.1s ease-out;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 3px;
  }
  &:after {
    margin-top: 9px;
  }
}

// Exceptions: only add what you need!
// .mb0.mb0 { margin-bottom: 0; }
// .mb20.mb20 { margin-bottom: 20px; }
.mb40.mb40 {
  margin-bottom: 40px;
}

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before,
  &:after {
    display: none;
  }
}

.large--equal-h {
  @media #{$larger} {
    display: flex;
    flex-wrap: wrap;

    > * {
      display: flex;
    }

    &:before,
    &:after {
      display: none;
    }
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  padding: 5px 0;
  background-color: $white;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.2s ease-out, top 0.4s ease-out;

  @media #{$navDesktop} {
    padding: 20px 0;
  }

  @media #{$large} {
    // padding: 51px 0 55px 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $white;
      z-index: 0;
    }
  }

  & > .container {
    @media #{$maxMedium} {
      padding-right: 0;
    }
  }
}

.anchor {
  display: block;
  visibility: hidden;
  height: 52px;
  margin-top: -52px;

  @media #{$navDesktop} {
    height: 85px;
    margin-top: -85px;
  }

  @media #{$large} {
    height: 130px;
    margin-top: -130px;
  }
}

.page-header--hide {
  @media #{$navDesktop} {
    top: -85px;

    .travels__filters {
      padding-top: 10px;

      .travels__filters-content {
        height: calc(100vh - 60px);
      }
    }
  }
  @media #{$large} {
    top: -130px;

    .travels__filters {
      padding-top: 0;
      top: calc(100% + 55px);

      .travels__filters-content {
        height: 100vh;
      }
    }
  }
}

.page-header__content {
  position: relative;
  padding-left: 150px;

  @media #{$navDesktop} {
    padding-left: 180px;
  }

  @media #{$huge} {
    padding-left: 245px;
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 150px;

  img {
    display: block;
  }

  &:hover,
  &:focus {
    @include opacity(80);
  }

  @media #{$navDesktop} {
    width: 180px;
  }

  @media #{$huge} {
    width: 244px;
  }
}

.main-nav {
  @include optimize-font;
  color: $black;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .main-nav__wishlist {
    .icon {
      transition-duration: 0s;
    }
  }

  a {
    text-decoration: none;
  }

  @media #{$navDesktop} {
    margin: 0;
    flex-direction: column;
    align-items: flex-end;
  }
}

.mobile-account-toggle {
  padding: 12px;

  &.active {
    color: $accent;
  }
}

.main-nav__trigger {
  display: inline-block;
  padding: 12px;
  background-color: transparent;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  margin: 0;
  z-index: 100;
  line-height: 1em;

  .icon {
    transform: scaleX(-1);
  }

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  @media #{$navDesktop} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;

  li {
    color: $black;
    padding: 20px 15px;
    display: block;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    text-align: center;
    white-space: nowrap;

    &:hover,
    &:focus,
    &.active {
      @media #{$medium} {
        &:before {
          width: 100%;
        }

        &.main-nav__wishlist,
        &.main-nav__quotation {
          a {
            color: $accent;
          }

          &:before {
            content: none;
          }
        }

        &.main-nav__quotation {
          a {
            color: $white;
          }
        }
      }
    }

    @media #{$navDesktop} {
      border-top: 0;
      border-bottom: 0;
      padding: 0;
      display: inline-block;
      margin: 0 0 0 25px;
      vertical-align: middle;

      &:last-of-type {
        line-height: 1em;
        margin-bottom: 2px;
      }

      &:before {
        content: "";
        display: block;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 0;
        transform: translateX(-50%);
        transition: width 0.2s ease-out;
        background-color: $accent;
      }
    }

    @media #{$huge} {
      margin-left: 44px;
      font-size: 18px;
    }
  }

  @media #{$navDesktop} {
    margin: 0 0 0 -20px;
    display: block;
  }

  @media #{$navMobile} {
    flex-direction: column;
    justify-content: center;
    display: flex;
    text-align: left;
    position: fixed;
    width: 0;
    bottom: 0;
    top: 52px;
    left: 0;
    margin: 0;
    background-color: $black;
    overflow: hidden;
    transition: width 0.4s ease;
    z-index: 99;

    &.open-mobile {
      width: 100%;
    }

    li.active {
      background: #151515;
    }

    a:not(.mobile-account-toggle) {
      color: $white;
      font-size: 25px;
    }
  }
}

.main-nav__wrap--secondary {
  li {
    font-size: 14px;

    @media #{$huge} {
      margin-left: 32px;
      font-size: 15px;
    }
  }

  @media #{$navDesktop} {
    margin-bottom: 15px;
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  padding: 52px 0 40px 0;
  position: relative;
  flex-grow: 1;

  @media #{$navDesktop} {
    padding: 145px 0 0;
  }

  // @media #{$large} {
  //   padding: 145px 0 0;
  // }
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  background-color: $black;
  padding: 60px 0 0px;
  font-weight: 300;
  font-size: 15px;
  color: $white;

  label {
    color: $white;
  }

  .grid {
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      line-height: 28.5px;
      a {
        line-height: 28.5px;
      }
    }
  }

  a {
    text-decoration: none;
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.page-footer__top-buttons {
  position: absolute;
  top: -60px;
  right: 20px;
  text-align: right;
  width: 100%;

  @media #{$large} {
    right: 40px;
  }

  @media #{$massive} {
    right: 40px;
  }

  .button {
    float: none;
    display: inline-block;
    padding: 12px 24px;
    font-size: 14px;
    margin: 0;
    margin-right: 6px;
    transform: translateY(-50%);

    &:last-of-type {
      margin-right: 0;
    }
  }

  .button--advice {
    display: none;

    @media #{$medium} {
      display: inline-block;
    }
  }

  .button--scroll-to-top {
    position: relative;
    padding-right: 33px;

    .icon {
      position: absolute;
      right: 13px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 13px;
    }
  }
}

.btn-personal-advice-wrap {
  display: none;
  position: relative;

  &.js-initialized {
    display: inline-block;
  }

  &.js-opening-advice {
    #btn-personal-advice {
      span {
        opacity: 0;
      }
    }
  }
}

#btn-personal-advice {
  span {
    transition: all 0.15s ease-out;
  }
}

#btn-scroll-to-top {
  display: none;
  &.js-initialized {
    display: inline-block;
  }
}

.page-footer__personal-advice {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  text-align: left;
  background-color: $accent;
  transform: translateY(-20px);
  color: $white;
  padding: 15px 20px;
  border-radius: 2px;
  font-size: 14px;
  width: 165px;
  height: 40px;
  overflow: hidden;
  transition: all 0.4s ease-out, opacity 0s ease-out;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  * {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  a {
    color: $white;

    &.contact-link {
      border-bottom: 1px solid $white;
    }
  }

  &.js-open-left {
    width: 388px;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &.js-close-left {
    width: 165px;
    opacity: 1;
    transition: all 0.15s ease-out, opacity 0s ease-out;
  }

  &.js-open-up {
    height: 182px;
    * {
      transition: opacity 0.6s ease-out;
      opacity: 1;
    }
  }
}

.page-footer__personal-advice-close-btn {
  color: $white;
  position: absolute;
  top: 11px;
  right: 11px;
  font-size: 10px;
  background-color: transparent;
  padding: 7px;
  border-radius: 2px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.page-footer__personal-advice-title {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.page-footer__personal-advice-strong {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.page-footer__logo {
  margin-bottom: 15px;
}

.page-footer__title {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  color: $white;

  @media #{$medium} {
    margin-top: 16px;
  }
}

.page-footer__2col {
  @media #{$medium} {
    column-count: 2;
  }

  @media #{$larger} {
    column-gap: 30px;
  }
}

.page-footer__main-grid {
  @include clearfix;

  & > .grid__item {
    justify-content: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    @media #{$medium} {
      margin-bottom: 0;
      padding: 0 20px;
      border-bottom: 0;

      &.page-footer__g-contact {
        padding-left: 0;
      }
    }

    @media #{$large} {
      border-bottom: 0;
      border-right: 1px solid rgba(255, 255, 255, 0.3);

      &.page-footer__g-contact {
        padding-left: 20px;
      }
    }

    @media #{$larger} {
      padding: 0 40px;

      &.padding-footer__g-contact {
        padding-left: 40px;
      }

      &.page-footer__g-about {
        padding: 0 30px;
      }
    }

    @media #{$massive} {
      padding: 0 70px;

      &.page-footer__g-contact {
        padding-left: 70px;
      }

      &.page-footer__g-about {
        padding: 0 60px;
      }
    }

    &:first-of-type {
      padding-left: 0;
      padding-right: 10px;
      justify-content: left;
    }

    &:last-of-type {
      padding-right: 0;
      border-bottom: 0;

      @media #{$large} {
        border-right: 0;
      }
    }
  }
}

.page-footer__g-main-info {
  @media #{$medium} {
    width: 33%;
  }

  @media #{$large} {
    width: 20%;
  }
}

.page-footer__g-about {
  @media #{$medium} {
    width: 66%;
  }

  @media #{$large} {
    width: 38%;
  }
}

.page-footer__g-contact {
  @media #{$medium} {
    width: 33%;
    clear: left;
    margin-top: 40px;
  }

  @media #{$large} {
    width: 27%;
    clear: none;
    margin-top: 0;
  }
}

.page-footer__g-social {
  @media #{$medium} {
    width: 66%;
    margin-top: 40px;
  }

  @media #{$large} {
    width: 14%;
    margin-top: 0;
  }
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 6px;
  transform: translate3d(0, 0, 0) scale(0.8);
  transform-origin: right center;

  &:hover,
  &:focus {
    opacity: 1;
  }
  @media #{$medium} {
    width: 16px;
    position: absolute;
    right: 102px;
    bottom: 5px;
    left: auto;
    &:hover,
    &:focus {
      opacity: 1;
      width: 80px;
    }
  }

  @media #{$larger} {
    right: 125px;
  }
}

.page-footer__legal {
  position: relative;
  left: 0;
  width: 100%;
  font-size: 11px;
  color: $grayDark;
  padding: 20px 0px 10px;

  a {
    color: $grayDark;
  }
}

.page-footer__logos {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: $gutter/2;

  .page-footer__logo {
    display: block;
    max-width: 60px;
    margin: 0 $gutter;
  }
}

.page-footer__subscribe {
  input {
    background: transparent !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    min-width: 115px;
    color: $white !important;
  }

  button {
    background: transparent !important;
    border-bottom: 1px solid $white;
    border-radius: 0;

    span {
      transition: $transitionDefault;
    }

    &:hover {
      span {
        transform: translateX(2px);
      }
    }
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail {
}

.article-detail__image {
  margin-bottom: 1.5em;
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul,
  p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover,
    &:focus {
      transition: none;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 1050px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}

.map--guide {
  height: 550px;
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  position: relative;

  .social-item__content {
    border: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: $transitionDefault;
  }

  .social-item__body {
    display: inline-grid;
    font-size: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    left: 0;
    right: 0;
    color: $white;
    text-align: center;
    padding: 0 10px;
    width: 100%;
    word-wrap: break-word;
    line-height: 140%;
    margin: 10px 0;

    span {
      white-space: pre-line;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .social-item__icons {
    position: absolute;
    bottom: $gutter;
    right: $gutter / 2;
    z-index: 0;

    img {
      margin-right: $gutter / 2;
    }
  }

  &:hover,
  &:focus {
    .social-item__content {
      opacity: 1;
    }

    .social-item__icons {
      z-index: -1;
    }
  }
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3c5b9b;
  &:hover,
  &:focus {
    background-color: darken(#3c5b9b, 8%);
  }
}

.social__link--twitter {
  background-color: #2daae1;
  &:hover,
  &:focus {
    background-color: darken(#2daae1, 8%);
  }
}

.social__link--pinterest {
  background-color: #cb2027;
  &:hover,
  &:focus {
    background-color: darken(#cb2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173b2;
  &:hover,
  &:focus {
    background-color: darken(#0173b2, 8%);
  }
}

.visual {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 66.66%; // picture default
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: "object-fit: cover; object-position: center;";
  }
}

.visual__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $grayLight;
  &.align-top {
    background-position: center top;
  }
  &.align-center {
    background-position: center center;
  }
  &.align-bottom {
    background-position: center bottom;
  }
}

.visual--canada-map {
  padding-top: 77.1%;
}

.visual-intro {
  position: relative;
  @include clearfix;
  padding: 80px 0;
  text-align: center;
  margin-bottom: 50px;

  & > .visual,
  & > .slider {
    padding-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.6s ease-out;
    overflow: hidden;
  }

  .slider {
    .visual {
      height: 430px;
      padding-top: 0;
    }
  }

  @media #{$medium} {
    height: 447px;
    padding-top: 100px;

    .slider {
      .visual {
        height: 447px;
      }
    }
  }

  @media #{$massive} {
    height: 645px;
    padding-top: 170px;

    .slider {
      .visual {
        height: 645px;
      }
    }
  }
}

#home-intro-slider-wrap {
  position: relative;

  .slick-dots {
    list-style: none;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    margin: 0;

    li {
      display: inline-block;

      &.slick-active {
        button {
          background-color: $accent;
        }
      }
    }

    button {
      width: 11px;
      height: 11px;
      background-color: $white;
      font-size: 0;
      border-radius: 50%;
      border: 2px solid $white;
      margin: 0 5px;
    }
  }
}

.visual-intro--sticky {
  transition: top 0.4s ease-out;

  .visual {
    background-color: $white;
  }

  .visual__img {
    transition: all 0.3s ease-out;
  }
}

.visual-intro__title {
  color: $white;
  font-size: 40px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.62);
  margin-bottom: 20px;
  @include display-font;
  line-height: 1.3em;

  @media #{$medium} {
    font-size: 70px;
    line-height: 1.1em;
    margin-bottom: 30px;
  }

  @media #{$massive} {
    font-size: 90px;
    margin-bottom: 40px;
  }
}

.visual-intro__button {
  border-radius: 2px;
  float: none;
  display: inline-block;

  @media #{$medium} {
    font-size: 18px;
  }

  @media #{$larger} {
    font-size: 20px;
  }
}

.visual-intro__extra-buttons-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
  width: 100%;
}

.visual-intro__extra-buttons {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media #{$larger} {
    padding-left: 100px;
    padding-right: 100px;
  }

  button,
  .button {
    margin: 0;
  }

  .button--return {
    padding: 12px;
    font-size: 13px;
    background-color: $white;
    border: 1px solid $grayishBlue;
    color: $black;
    display: none;
    line-height: 1em;

    @media #{$medium} {
      display: block;
      font-size: 22px;
    }

    &:hover {
      background-color: $grayLight;
    }
  }
}

.category-card {
  padding: 20px;
  background-color: $white;
  border: 1px solid $grayLight;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: border-color 0.2s ease-out;
  backface-visibility: hidden;
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.06);

  &:hover {
    border-color: $grayLight;
    transition: border-color 0.2s ease-out, background-color 0.4s ease-out;

    .category-card__icon {
      transform: scale(1.03);
      &:before {
        width: 470px;
        height: 470px;
        background-color: $grayLight;
      }

      .icon {
        &:after {
          background-color: $white;
          box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.12);
        }
      }
    }

    .category-card__title {
    }
  }

  @media #{$medium} {
    padding: 40px 20px;
  }

  a {
    display: block;
    text-decoration: none;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

.category-card__title {
  font-weight: 600;
  transition: all 0.05s ease-out;
}

.page-block {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 65px;
  }

  @media #{$massive} {
    margin-bottom: 95px;
  }
}

.quote {
  background-color: $grayLight;
  text-align: center;
  margin-top: 25px;
  padding: 0 60px 50px 60px;

  .visual {
    width: 124px;
    height: 124px;
    border-radius: 50%;
    overflow: hidden;
    padding-top: 0;
    display: inline-block;
    margin-top: -25px;
    margin-bottom: 25px;
  }
}

.quote__name {
  font-weight: 700;
  margin-bottom: 17px;
}

.quote__text {
  font-weight: 300;
  font-style: italic;
  font-size: 18px;

  @media #{$larger} {
    font-size: 20px;
  }
}

.pre-title {
  color: $accent;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @media #{$medium} {
    font-size: 15px;
  }
}

.travel-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  transition: all 0.2s ease-out;

  &:hover {
    .travel-card__visual {
      &:before {
        height: 5px;
      }
    }
  }

  a.travel-card__title {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.travel-card__top {
  flex-grow: 1;
  position: relative;

  .visual {
    overflow: hidden;
    background-color: $grayLight;
  }

  .visual__img {
    transition: transform 0.8s cubic-bezier(0.21, 0.96, 1, 1);
    backface-visibility: hidden;
  }

  &.top-hovered {
    .visual__img {
      transform: scale(1.03);
    }
  }
}

.travel-card__bottom {
  flex-grow: 0;
  min-height: 104px;
}

.travel-card__visual {
  margin-bottom: 20px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 0;
    left: 0;
    width: 100%;
    background-color: $accent;
    z-index: 1;
    transition: all 0.1s ease-out;
  }
}

.travel-card__categories,
.travel-detail__categories,
.wishlist-item__categories {
  font-size: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 46px;

  .icon {
    display: inline-block;
    vertical-align: bottom;
    margin: 0 20px 15px 0;
  }

  .icon-motorhome {
    font-size: 23px;
  }

  .icon-trees {
    font-size: 31px;
  }

  .icon-cruise-ship {
    font-size: 28px;
  }

  .icon-beach-chair {
    font-size: 32px;
  }

  .icon-car {
    font-size: 18px;
  }

  .icon-luxury {
    font-size: 27px;
  }

  .icon-tent {
    font-size: 28px;
  }

  .icon-train {
    font-size: 27px;
  }
}

.travel-card__title {
  margin-bottom: 10px;
  display: block;
  color: $accent;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.5em;
  @include display-font;
  font-size: 24px;

  &:hover,
  &:focus,
  &:active {
    color: $accent;
  }
}

.travel-card__general-info,
.travel-detail__general-info {
  color: $black;
  margin-bottom: 15px;
  font-weight: 400;
}

.travel-card__pricing {
  padding-top: 15px;
  margin-bottom: 15px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 38px;
    height: 2px;
    background-color: $accent;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.travel-card__wishlist {
  button {
    position: relative;
    z-index: 1;
    text-decoration: underline;
  }
}

.travel-card--wishlist {
  overflow: hidden;

  .travel-card__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: $white !important;
    padding: 10px 15px;
    border-radius: 0 0 0 2px;
    transition: all 0.3s ease-out, opacity 0.2s ease-out;

    @media #{$large} {
      opacity: 0;
      transform: translateX(100%);
    }

    &:hover {
      transition: all 0.3s ease-out, opacity 0.6s ease-out;
      background-color: $grayLight;
      cursor: pointer;
    }
  }

  .checkbox-item--action label {
    text-decoration: none;
  }

  .travel-card__pricing {
    padding-bottom: 18px;
    margin-bottom: 0;
  }

  .travel-card__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .travel-card__bottom {
    z-index: 2;
  }

  .travel-card__options-toggle {
    border-top: 1px solid $grayMiddle;
  }

  .checkbox-item {
    &.checked {
      background-color: $accent;
      label {
        color: white;
      }
    }
  }

  .option--excursion,
  .option--option {
    label {
      margin-bottom: 0;
    }
  }

  .checkbox-item--action {
    &.checked {
      background-color: transparent;
      label {
        color: $accent;
      }
    }
  }

  &.removing {
    transform: translateX(-30px);
    opacity: 0;
    transition-duration: 0.4s;
    .travel-card__close {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &:hover {
    .travel-card__close {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.travel-card__options-toggle {
  font-weight: 700;
  font-size: 17px;
  padding: 15px 40px 15px 0;
  position: relative;
  user-select: none;

  &:not(.inactive):hover {
    text-decoration: underline;
  }

  &.js-display-toggle--open {
    .icon {
      &:before {
        content: "\e918";
      }
    }
  }

  .icon {
    position: absolute;
    right: 0;
    top: 20px;
  }
}

.travel-card__options {
  transition: all 0.2s ease-out, visibility 0s ease-out 0.2s;
  display: none;

  .option {
    &:first-of-type {
      padding-top: 0;

      button {
        top: 5px;
      }
    }
  }

  &.js-display-visible {
    transition: all 0.2s ease-out, visibility 0s ease-out;
    display: block;
  }
}

.option {
  padding: 15px 70px 15px 0;
  border-bottom: 1px solid $grayMiddle;
  position: relative;
  transition: all 0.2s ease-out;
  overflow: hidden;

  button {
    position: absolute;
    top: 22px;
    right: 0;
    font-size: 12px;
    color: $grayMiddle;

    &:hover {
      color: $grayDark;
    }
  }
}

.overview-card__options-title {
  color: $black;
  font-weight: 400;
  margin-bottom: 0;
  padding-right: 40px;
  position: relative;
  transition: all 0.2s ease-out;

  .icon {
    font-size: 10px;
    position: absolute;
    right: 2px;
    top: 8px;
  }

  &.js-display-toggle--open {
    .icon {
      @extend .icon-minus;
    }
  }
}

.overview-card__options {
  transition: all 0.2s ease-out, visibility 0s ease-out 0.2s;
  overflow: hidden;
  display: none;

  .option {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.js-display-visible {
    display: block;
  }
}

.option--hiding {
  transform: translateX(-40px);
  opacity: 0;
}

.option--hidden {
  padding: 0;
  height: 0;
  border: 0;
}

.travel-card__select-for-quotation {
  margin-top: 20px;
}

.travel-card__select-for-quotation .checkbox-item {
  background-color: $grayMiddle;
  padding: 10px 20px;
  transition: all 0.1s ease-out;
  position: relative;
  border-radius: 2px;

  &:hover {
    background-color: $accent;
    label {
      color: $white;
    }
  }

  label {
    color: $black;
    margin-bottom: 0;
    font-weight: 400;
    padding: 0 10px 0 30px;

    @media #{$massive} {
      padding: 0 37px 0 30px;
    }
    text-align: center;
    transition: all 0.1s ease-out;

    &:before {
      content: "";
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  input,
  .checkmark {
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.wishlist-card-grid {
  .grid__item {
    @media #{$small} {
      &:nth-of-type(2n + 3) {
        clear: left;
      }
    }
    @media #{$large} {
      &:nth-of-type(2n + 3) {
        clear: none;
      }

      &:nth-of-type(3n + 4) {
        clear: left;
      }
    }
  }
}

.wishlist-button {
  background-color: transparent;
  font-weight: 300;

  a {
    color: $accent;
    display: none;
  }

  .wishlist-button__heart {
    .icon-heart-full {
      opacity: 0;
    }
  }

  &:hover {
    .wishlist-button__heart {
      .icon-heart-full {
        clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
        opacity: 1;
      }
    }
  }

  &.wishlist-added {
    .wishlist-button__heart {
      .icon-heart {
        color: #bd0000;
      }

      .icon-heart-full {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        opacity: 1;
      }
    }

    span {
      display: none;
    }

    a {
      display: inline-block;
      text-decoration: none;
      font-weight: 700;
    }
  }
}

.wishlist-button__heart {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 21px;
  margin-right: 10px;
  margin-bottom: 2px;
  vertical-align: middle;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 21px;
  }

  .icon-heart-full {
    color: $accent;
    transform: translateX(-50%) translateY(-50%) scale(0.95);
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    transition: all 0.2s ease-out;
  }
}

.travels__filters-title {
  font-size: 22px;
  margin-bottom: 30px;
  @include display-font;
}

.filter-item {
  label {
    font-weight: 300;
  }
}

.filter-item__title {
  font-weight: 400;
  margin-bottom: 15px;
}

.gallery {
  @include clearfix;

  &:before {
    content: "";
    display: block;
    float: left;
    padding-top: calc(66.66% + 135px);
    width: 0;
    height: 0;
  }
}

.gallery-preview {
}

.gallery-preview__photo {
  position: relative;

  .visual {
    overflow: hidden;
  }

  .visual__img {
    transition: all 0.2s ease-out;
  }

  &:hover {
    .visual__img {
      transform: scale(1.03);
    }

    .gallery-preview__photo-overlay {
      background-color: rgba(245, 245, 245, 0.6);
    }
  }
}

.gallery-preview__photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.2s ease-out;
  background-color: rgba(245, 245, 245, 0.78);
}

.gallery-preview__photo-overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: $black;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;

  @media #{$medium} {
    font-size: 18px;
  }
}

.fullscreen-popup {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 100000;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  display: none;
  overflow: hidden;

  .slider {
    display: none;
  }

  &.js-open {
    display: block;
    visibility: visible;
    opacity: 1;
    overflow-y: scroll;
    pointer-events: auto;

    .container {
      pointer-events: none;
    }

    .fullscreen-popup__content {
      position: relative;
      pointer-events: auto;
      margin-bottom: 10px !important;
      @include clearfix;

      .container {
        pointer-events: auto;
      }
    }

    .slider {
      display: block;
    }
  }

  .gallery-thumb-slider {
    margin-bottom: 0;
  }
}

.fullscreen-popup--fitting-content {
  text-align: center;

  .fullscreen-popup__content {
    display: inline-block;
    text-align: left;
    background-color: white;
  }
}

.fullscreen-popup__scroll-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  padding: 20px;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }

  &.js-larger-than-window {
    top: 20px;
    transform: translateX(-50%);
  }
}

.fullscreen-popup__close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: $white !important;
  border-radius: 50%;
  line-height: 1;
  padding: 15px;
  font-size: 10px;
  transform: translateX(33%) translateY(-33%);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);

  @media #{$medium} {
    font-size: 16px;
    padding: 20px;
  }

  &:hover {
    background-color: $grayLight !important;
  }
}

.excursion-popup {
  max-height: 100vh;
  padding: 0;

  &.js-open {
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.excursion-popup__content {
  .fullscreen-popup__close {
    position: fixed;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.12);
    right: 40px;
    top: 40px;

    @media #{$medium} {
      right: 80px;
      top: 60px;
    }
  }
}

.arrow-link {
  text-decoration: none;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: $accent !important;
  display: inline-block;
  border-bottom: 1px solid $accent;
  margin-bottom: 20px;
  user-select: none;
  @include optimize-font;

  &:after {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 18px;
    margin-bottom: 2px;
    transition: transform 0.2s ease-out;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @extend .icon-arrow-right:before;
    font-size: 20px;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }

  &:hover,
  &:focus,
  &:active {
    color: $accent;

    &:after {
      transform: translateX(3px);
    }
  }
}

.checkbox-item {
  position: relative;
  padding-left: 30px;

  &:hover,
  label {
    cursor: pointer;
  }

  label {
    user-select: none;
    font-weight: 300;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;

    @media #{$medium} {
      top: 3px;
    }
  }

  .checkmark {
    @media #{$medium} {
      top: 3px;
    }
  }
}

.checkbox-item--action {
  label {
    text-decoration: underline;
  }

  &:hover {
    label {
      color: $accent;
    }
  }
}

.accent-message {
  background-color: $grayLight;
  color: $black;
  padding: 20px;
  margin-bottom: 40px;

  .checkbox-item {
    color: $black;
    margin-top: 20px;
    @media #{$medium} {
      float: right;
      margin-left: 20px;
      margin-top: 0;
    }
    label {
      color: $black;
      margin-bottom: 0;
      font-size: 14px;

      @media #{$medium} {
        font-size: 16px;
      }
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .accent-message__line {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.js-display-toggle {
  user-select: none;
  cursor: pointer;
}

.travel-card--excursion {
  .travel-card__text-preview {
    height: 3em;
    margin-bottom: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .travel-card__bottom {
    min-height: 0;
  }

  .travel-card__pricing {
    margin-bottom: 0;
  }

  .arrow-link {
    border-bottom: 0;
  }
}

.gallery-thumb-slider-wrap {
  position: relative;
  margin-bottom: 16px;
}

.gallery-thumb-slider {
  margin: 16px 0;
}

.gallery-thumb {
  margin: 0 5px;
  transition: all 0.2s ease-out;

  &.slick-current {
    opacity: 0.4;
  }
}

.gallery-thumb__img {
  height: 103px;
  width: auto;
  transition: all 0.4s ease-out;
}

.excursion-gallery__visual {
  padding-top: 66.66%;
}

.excursion-gallery__photo {
  background-size: contain;
}

.slider-action {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: $white;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: $grayLight;
  }

  &.slider-action--alternative {
    &:hover {
      background-color: $white;

      .icon:before {
        color: $accent;
      }
    }
  }
}

.slider-action--left {
  left: 0;
  border-left: 0;
  padding: 0 7px 2px 7px;

  .icon {
    transform: scaleX(-1);
  }

  &.slider-action--alternative {
    transform: scale(1.5) translateX(-40%);
    transform-origin: left center;

    @media #{$medium} {
      transform: scale(2) translateX(-40%);
    }
  }
}

.slider-action--right {
  border-right: 0;
  padding: 0 7px 2px 7px;
  right: 0;

  &.slider-action--alternative {
    transform: scale(1.5) translateX(40%);
    transform-origin: right center;

    @media #{$medium} {
      transform: scale(2) translateX(40%);
    }
  }
}

.slider {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease-out;

  .slider__slide {
    backface-visibility: hidden;
    display: none;
    transition-duration: 0s;
    &:first-of-type {
      display: block;
    }
  }

  &.slick-initialized {
    opacity: 1;
    visibility: visible;
    transition-duration: inherit;

    .slider__slide {
      transition-duration: inherit;
      display: block;
    }
  }
}

.overview-card-wrap {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  padding: 10px 20px 20px;
  min-width: 300px;
  border: 1px solid transparent;

  .button {
    padding: 10px 20px;

    .icon {
      margin-left: 6px;
      transition: none;
    }
  }

  @media #{$small} {
    left: auto;
    padding: 20px;
    bottom: 40px;
    right: 40px;
    width: 350px;
    pointer-events: none;

    &.js-show-overview-card {
      box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.12);
      border: 1px solid $grayLight;
      background-color: $white;
      pointer-events: auto;

      .overview-card-show-trigger {
        display: none;
      }

      .overview-card {
        display: block;
      }
    }
  }

  @media #{$maxSmall} {
    text-align: center;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.12);
    border: 1px solid $grayLight;
    background-color: $white;

    .button {
      box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.12);
    }

    .overview-card__cta {
      margin: 0;
    }

    .button {
      margin: 0;
    }

    & > .overview-card > *:not(.overview-card__cta) {
      display: none;
    }
  }
}
.overview-card {
  @media #{$small} {
    display: none;
  }
}

.overview-card-show-trigger {
  display: none;
  pointer-events: auto;

  @media #{$small} {
    display: block;
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 10px 18px !important;
    background-color: $white;
    color: $accent;
    z-index: -1;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.12);

    &:hover {
      background-color: $white;
      color: $accentHover;
    }
  }
}

.overview-card-close-trigger {
  position: absolute;
  top: 18px;
  right: 15px;
  font-size: 10px;
  background-color: transparent;
  padding: 7px;
  border-radius: 2px;
  line-height: 1em;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.03);
  }
}

.overview-card__title {
  font-weight: 700;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 30px;
}

.overview-card__cta {
  margin-top: 10px;

  @media #{$small} {
    text-align: right;
  }
}
.overview-card__options-count {
  margin-bottom: 10px;

  @media #{$small} {
    display: none;
  }
}

.team-card {
  text-align: center;
  border: 1px solid $grayMiddle;
  padding: 0 35px 35px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 65px;
  }
}

.team-card__visual {
  overflow: hidden;
  border-radius: 50%;
  width: 188px;
  height: 188px;
  padding-top: 0;
  display: inline-block;
  transform: translateY(-11px);
  margin-bottom: 20px;
}

.team-card__name {
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 14px;
}

.team-card__text {
  font-size: 17px;
  line-height: 1.67em;
  flex-grow: 1;
}

.team-card__mail {
  color: $accent;
  text-decoration: none;
  position: relative;
  font-weight: 700;
  padding-bottom: 5px;

  &:hover,
  &:focus,
  &:active {
    color: $accentHover;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $accent;
  }
}

.relative {
  position: relative;
}

.category-visuals {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media #{$medium} {
    margin-bottom: -20px;
    flex-wrap: nowrap;
  }
}

.category-visuals__el {
  text-align: center;
  position: relative;
  padding: 20px 8px;
  transition: $transitionDefault;
  transition-duration: 0.15s;
  border: 1px solid transparent;
  width: 50%;

  @media #{$medium} {
    padding: 20px 10px;
    width: auto;
    margin-bottom: 20px;
  }

  @media #{$large} {
    padding: 20px 40px;
    width: 20%;
  }

  &:hover,
  &:focus {
    background-color: $grayLight;
  }

  a {
    color: $accent;
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 11px;
    white-space: nowrap;

    @media #{$medium} {
      font-size: 16px;
    }

    .html-rarr {
      display: inline-block;
      margin-left: 5px;
      transform: translateY(-1px);

      @media #{$medium} {
        transform: translateY(-2px);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

.category-visuals__seperator {
  display: none;

  @media #{$medium} {
    display: block;
    flex-grow: 1;
    height: 1px;
    background-color: $black;
    margin: 0 30px 30px 30px;

    &:last-of-type {
      display: none;
    }
  }
}

.category-visuals__visual {
  position: relative;
  z-index: 0;
  transition: all 0.2s ease-out;

  @media #{$medium} {
    margin-bottom: $gutter;
  }

  img {
    font-size: 51px;
    display: inline-block;
    position: relative;
    z-index: 0;
    height: 50px;
    width: auto;
    transition: all 0.2s ease-out;

    @media #{$medium} {
      height: 60px;
    }
  }

  &--winter {
    img {
      width: 30px;

      @media #{$medium} {
        width: 40px;
      }
    }
  }

  &--motorhome {
    img {
      width: 50px;

      @media #{$medium} {
        width: 60px;
      }
    }
  }

  &--auto {
    img {
      width: 50px;
      transform: translateY(4px);

      @media #{$medium} {
        width: 60px;
        transform: translateY(7px);
      }
    }
  }

  &--trein {
    img {
      @media #{$maxMedium} {
        width: 60px;
      }
    }
  }

  &--luxe {
    img {
      width: 30px;
      transform: translateY(-5px);

      @media #{$medium} {
        width: 40px;
        transform: translateY(-10px);
      }
    }
  }
}

.visual-quote {
  position: relative;

  blockquote {
    background-color: $accent;
    color: $white;
    padding: 45px;
    border: 0;
    margin-bottom: 0;

    p {
      @include display-font;
      font-size: 20px;
      &:last-of-type {
        margin-bottom: 40px;

        @media #{$massive} {
          margin-bottom: 70px;
        }
      }
    }

    cite {
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      padding-left: 50px;
      position: relative;
      display: inline-block;
      line-height: 1.5em;

      &:before {
        content: "";
        display: block;
        position: absolute;
        vertical-align: center;
        width: 40px;
        height: 1px;
        left: 0;
        top: 11px;
        background-color: $white;
        margin-right: 10px;
        line-height: 15px;
      }
    }
  }

  @media #{$large} {
    padding: 50px 0 50px 30%;

    .visual {
      position: absolute;
      left: 0;
      top: 0;
      width: 70%;
      bottom: 0;
      padding-top: 0;
      height: auto;
      z-index: -1;
    }
  }
}

.contact-link {
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid $black;

  .icon {
    margin-left: 10px;
    margin-bottom: 2px;
  }
}

.highlight-preview {
  margin-bottom: 30px;
  position: relative;

  &:hover {
    transition: all 0.2s ease-out;
    background-color: #fafafa;

    .highlight-preview__visual img {
      transform: scale(1.03);
    }
  }

  @media #{$medium} {
    padding: 30px;
    background-color: $white;
    border: 1px solid #e1e1e1;
    margin-bottom: 60px;
  }
}

.highlight-preview__visual {
  .visual img {
    transition: transform 0.8s cubic-bezier(0.21, 0.96, 1, 1);
    backface-visibility: hidden;
  }

  @media #{$maxMedium} {
    margin-bottom: 20px;
  }
}

.highlight-preview__tagline {
  @extend .h1;
  margin-bottom: 0.5em;

  @media #{$medium} {
    margin-top: 0.5em;
  }

  @media #{$massive} {
    font-size: 36px;
  }
}

.highlight-preview__title {
  color: $accent;
  display: block;
  text-decoration: none;
  @include display-font;
  font-size: 24px;
  margin-bottom: 15px;

  &:hover {
    color: $accentHover;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.highlight-preview__info {
  color: $black;
  margin-bottom: 15px;
  font-weight: 400;
  padding-top: 15px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 38px;
    height: 2px;
    background-color: $accent;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.highlight-preview__description {
  margin-top: 1.5em;

  p {
    margin-bottom: 1em;
  }
}

// Accordion
.accordion {
  border-bottom: 1px solid $grayishBlue;
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }
}

.accordion__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0;
  transition: $transitionDefault;
  font-size: 14px;

  .filter-item__title {
    width: 100%;
    margin-bottom: 0;
  }

  // &:hover,
  // &:focus {
  //   color: $accent;
  // }
}

.accordion__trigger {
  position: relative;
  &:after {
    content: "+";
    right: 0px;
    top: 0;
    width: 10px;
    height: 10px;
  }
}

.accordion__title--active {
  .accordion__trigger {
    &:after {
      content: "-";
    }
  }
}

.accordion__content {
  transition: height 0.5s ease-in-out;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 10px;
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */

.home-intro {
  margin-bottom: 30px;

  .visual__img {
    background-image: url("../images/home-intro--mobile.jpg");

    @media #{$medium} {
      background-image: url("../images/home-intro.jpg");
    }
  }
}

.home-category-visuals {
  @media #{$medium} {
    padding: 40px 0;
  }
}

.home-about__main-content {
  @include clearfix;
  margin-bottom: 40px;
  font-weight: 300;

  @media #{$larger} {
    font-size: 17px;
    padding-right: 60px;
  }
}

.home-about__title {
  font-weight: 400;
  padding-left: 15px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $accent;
  }

  @media #{$medium} {
    font-size: 50px;
    margin-bottom: 30px;
    padding-left: 30px;
  }
}

.home-visual-quote {
  @media #{$large} {
    max-width: 550px;
    margin-left: auto;
    margin-top: 55px;
  }

  @media #{$massive} {
    margin-top: 0;
  }
}

.instagram__logo {
  width: 30px;
  height: 30px;
  margin-top: -5px;
}

.home-travel__title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media #{$medium} {
    font-size: 18px;
  }
}

.home-travel {
  .grid__item:nth-child(4) {
    display: none;

    @media #{$small} {
      display: block;
    }

    @media #{$large} {
      display: none;
    }
  }
}

.travels__overview {
  position: relative;
  margin-bottom: 40px;
  @include clearfix;
}

.travels__filters {
  @include clearfix;
  margin-bottom: 20px;
  float: left;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $white;

  .travels__filters-toggle {
    margin-left: 20px;
    margin-right: 20px;
  }

  .travels__filters-content {
    height: calc(100vh - 105px);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media #{$medium} {
    top: calc(100% + 20px);

    .travels__filters-content {
      height: calc(100vh - 135px);
    }
  }

  @media #{$large} {
    width: 350px;
    margin-bottom: 0;
    padding-left: 40px;
    padding-top: 0;
    left: 0;
    top: 110%;
    top: calc(100% + 35px);
    float: none;
    perspective: 3000px;
    backface-visibility: hidden;
    transition: top 0.4s ease-out;
    z-index: 1;

    .travels__filters-content {
      padding-top: 35px;
      height: calc(100vh - 110px);
    }
  }

  @media #{$larger} {
    padding-left: 100px;
    width: 430px;
  }

  .filter-item {
    margin-bottom: 20px;

    @media #{$medium} {
      width: 50%;
      float: left;
      margin-bottom: 40px;
    }

    @media #{$large} {
      width: 100%;
      float: none;

      &:nth-of-type(2n + 3) {
        clear: left;
      }
    }
  }
}

.travels-personal-advice {
  border: 1px solid $grayishBlue;
  padding: 20px;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.travels__travel-cards {
  float: left;
  width: 100%;

  @media #{$large} {
    padding-left: 380px;
  }

  @media #{$massive} {
    padding-left: 420px;
  }
}

.travels__filters-toggle {
  display: block;
  margin: 10px 0;
  padding: 10px 30px 10px 10px;
  border: 1px solid $grayMiddle;
  border-radius: 2px;
  position: relative;
  transition: all 0.2s ease-out;

  &:hover,
  &:focus {
    cursor: pointer;
    border-color: $gray;
    &:before {
      color: $black;
    }
  }

  &.js-display-toggle--open {
    &:before {
      content: "-";
    }
  }

  &:before {
    content: "+";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: $gray;
    transition: all 0.2s ease-out;
  }

  @media #{$large} {
    display: none;
  }
}

.travels__filters-content {
  display: none;

  @media #{$large} {
    display: block;
  }

  &.js-display-visible {
    display: block;
  }
}

.travels__filters-selected {
  margin: 0 0 35px 0;
  ul {
    margin: 0;
    list-style: none;

    li:not(:last-of-type) {
      background-color: $accent;
      display: inline-block;
      color: $white;
      padding: 7px 40px 7px 10px;
      border-radius: 2px;
      white-space: nowrap;
      position: relative;
      margin-bottom: 5px;
      margin-right: 1px;

      button {
        padding: 5px 6px;
        line-height: 1em;
        background-color: transparent;
        color: $white;
        font-size: 11px;
        margin-bottom: 4px;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        right: 10px;
        top: 8px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    li:last-of-type {
      display: block;

      button {
        text-decoration: underline;

        &:hover {
          color: $accent;
        }
      }
    }
  }
}

.travels__travel-cards-nr {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: right;

  @media #{$medium} {
    margin-bottom: 24px;
  }
}

.travel-detail {
  h2 {
    color: $accent;
    font-size: 24px;
  }
}

.travel-detail__content {
  @media #{$larger} {
    padding: 0 70px;
  }
}

.travel-detail__intro {
  .visual-intro__button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    font-weight: 300;
    display: none;

    @media #{$medium} {
      display: inline-block;
      padding: 15px 60px;
    }
  }
}

.travel-detail__hero-photo-location {
  position: absolute;
  right: 0;
  top: 10px;
  background-color: $white;
  padding: 2px 10px;
  font-size: 12px;

  @media #{$medium} {
    font-size: 16px;
    top: 30px;
    padding: 6px 26px;
  }
}

.travel-detail__heading {
  margin-bottom: 33px;
}

.price-table-wrap {
  @media #{$maxMedium} {
    width: calc(100% + 20px);
    overflow-x: auto;
  }
}

.price-table {
  td {
    text-align: right;
  }

  @media #{$maxMedium} {
    font-size: 12px;

    .price-table__price-row-title {
      font-size: 12px;
    }
  }

  .price-table__group-header {
    font-size: 16px;
  }
}

.price-table__group-header {
  background-color: $grayLight;

  th {
    border-bottom: 0;
    font-weight: 300;
  }
}

.price-table__group-divider {
  height: 40px;

  &:first-of-type {
    height: 0;
  }
}

.price-table__header-row {
  color: $accent;
  font-size: 13px;

  th {
    text-align: right;
    white-space: nowrap;
  }
}

.price-table__price-row-title {
  font-size: 13px;
}

.top-bar {
  position: fixed;
  top: 52px;
  left: 0;
  width: 100%;
  background: $white;
  border: 1px solid $grayLight;
  padding: 10px 0;
  z-index: 100;
  opacity: 1;
  transform: translateY(-100%);
  transition: all 0.4s ease-out;

  @media #{$medium} {
    top: 0;
  }

  &.js-show {
    opacity: 1;
    transform: translateY(0);
  }

  &.js-put-below-menu {
    @media #{$medium} {
      top: 85px;
    }

    @media #{$large} {
      top: 130px;
    }
  }

  &.js-hide-below-menu {
    top: 0;
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.4s ease-out, opacity 0s ease-out 0.4s,
      top 0s ease-out 0.4s;
  }
}

.top-bar__button {
  padding: 13px 20px;
  margin-top: 0;
  white-space: nowrap;
}

.travel-detail__top-bar {
  .top-bar__content {
    display: flex;
  }

  .top-bar__message {
    margin-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    font-weight: 500;
    font-size: 12px;
    margin-top: 8px;
    color: $black;

    @media #{$medium} {
      font-size: 20px;
    }

    a {
      color: $accent;
      text-decoration: none;

      &:hover {
        color: $accentHover;
      }
    }
  }
}

.travel-detail__title {
  margin-bottom: 5px;
  color: $accent;
}

.travel-detail__subtitle,
.excursion__subtitle {
  color: $accent;
  font-weight: 700;
  font-size: 17px;
  @include optimize-font;
  margin: 0 0 0.8em;
}

.transparent-popup {
  background: rgba(0, 0, 0, 0.85);

  & > .container {
    background: $white;
  }
}

.map-popup {
  text-align: center;
}

.map-popup-content {
  position: relative;
  display: inline-block;

  img {
    max-height: calc(100vh - 100px);
    max-width: 100%;
  }

  .fullscreen-popup__close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.travel-detail__gallery-preview,
.travel-detail__description {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 60px;
  }

  @media #{$larger} {
    margin-bottom: 100px;
  }
}

.travel-detail__description {
  @media #{$larger} {
    margin-bottom: 0;
  }
}

.travel-detail__canada-map {
  @media #{$medium} {
    margin-top: -38px;
  }

  @media #{$large} {
    padding-left: 10px;
  }

  @media #{$massive} {
    margin-top: -78px;
  }

  @media #{$maxMedium} {
    max-width: 400px;
    margin: 0 auto;
  }

  img:hover {
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
}

.travel-detail__description-text {
  @media #{$large} {
    margin-right: 55px;
  }
}

.travel-detail__scheme {
  h2 {
    margin-bottom: 40px;
  }
}

.travel-scheme-slider-wrap {
  position: relative;
  padding-top: 20px;
}

.travel-scheme-slider {
  margin-bottom: 45px;

  .slick-list {
    overflow: visible;
  }

  .slider__slide {
    &:not(.slick-current) {
      * {
        pointer-events: none;
      }

      @media #{$larger} {
        opacity: 0.3;
        transition: opacity 0.4s;
      }

      &:hover {
        cursor: pointer;

        @media #{$larger} {
          opacity: 0.8;
        }
      }
    }
  }
}

.travel-scheme-slider-actions {
  @include clearfix;
  .slider-action {
    color: $accent;
    padding: 10px;
    top: 0;
    border: 0;
  }

  .slider-action {
    transition: all 0.2s ease-out;
  }

  .slider-action--left {
    left: -10px;
    visibility: hidden;
    opacity: 0;

    .icon {
      margin-right: 10px;
      margin-bottom: 2px;
    }
  }

  .slider-action--right {
    right: -20px;

    @media #{$large} {
      right: 20px;
    }

    .icon {
      margin-bottom: 2px;
      margin-left: 10px;
    }
  }
}

.travel-scheme-card__day-preview {
  margin-bottom: 0;
  position: relative;

  @media #{$medium} {
    margin-bottom: 40px;
  }

  .travel-scheme-card__title {
    @include default-font;

    a {
      text-decoration: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &:hover {
    a {
      &:after {
        transform: translateX(3px);
      }
    }
  }
}

.travel-scheme-title {
  text-align: center;

  @media #{$larger} {
    text-align: left;
  }
}

.travel-scheme-card {
  border: 1px solid $grayMiddle;
  padding: 30px 15px 0 15px;
  margin-right: 15px;

  @media #{$medium} {
    margin-right: 30px;
    padding: 60px 70px 0 70px;

    .fullscreen-popup-trigger {
      pointer-events: none;

      &.arrow-link {
        display: none;
      }
    }
  }
}

.travel-scheme-card__day-main-visual {
  margin-bottom: 40px;
}

.travel-scheme-card__title {
  font-size: 18px;
  margin-bottom: 15px;
  @include display-font;
  font-weight: 700;

  @media #{$maxMedium} {
    min-height: 2.6em;
  }
}

.travel-scheme-card__title--option {
  margin-bottom: 5px;
  font-size: 14px;

  a {
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    color: $accent;

    @media #{$large} {
      color: $black;
    }
  }

  @media #{$medium} {
    font-size: 17px;
  }
}

.travel-scheme-card__day-title {
  font-weight: 600;
}

.travel-scheme-card__day-preview-text {
  overflow: hidden;
  max-height: 3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;

  @media #{$medium} {
    max-height: 12em;
    -webkit-line-clamp: 8;
    margin-bottom: 1.5em;
  }
}

.travel-scheme-card__optional {
  & > .travel-scheme-card__title {
    margin-bottom: 25px;
  }
}

.travel-scheme-card__optional-item {
  margin-bottom: 10px;
  display: flex;
  position: relative;

  @media #{$large} {
    margin-bottom: 40px;
  }
}

.travel-scheme-card__option-visual {
  margin-bottom: 20px;
  min-width: 107px;
  margin-right: 15px;

  @media #{$medium} {
    min-width: 168px;
    margin-right: 35px;
  }
}

.travel-card__optional-text-content {
  flex-grow: 1;

  .arrow-link {
    display: none;
  }

  &:hover {
    .arrow-link {
      &:after {
        transform: translateX(3px);
      }
    }
  }

  @media #{$large} {
    padding-right: 35px;

    .arrow-link {
      display: inline-block;
    }

    .checkbox-item {
      display: none;
    }
  }

  @media #{$maxMedium} {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .arrow-link,
  .checkbox-item label {
    font-size: 12px;

    @media #{$medium} {
      font-size: 16px;
    }
  }
}

.travel-scheme-card__option-practical-info {
  position: relative;
  display: none;

  .checkbox-item {
    margin-top: 20px;
  }

  @media #{$large} {
    display: block;
    min-width: 225px;
    padding-left: 35px;
    padding-top: 20px;
    z-index: 1;

    &:before {
      content: "";
      background-color: $grayMiddle;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 20px;
      width: 1px;
    }

    &:after {
      content: "";
      z-index: -1;
      display: block;
      position: absolute;
      left: 0;
      top: -20px;
      bottom: 0;
      right: -20px;
    }
  }
}

.travel-scheme-card__option-text-preview {
  margin-bottom: 9px;
  height: 3em;
  overflow: hidden;
  display: none;

  @media #{$medium} {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.travel-detail__pricing {
  @media #{$large} {
    margin-bottom: 70px;
  }
}

.travel-detail__optional {
  background-color: $grayLight;
  margin-bottom: 40px;
  padding: 20px 15px 1px 15px;

  h2 {
    font-weight: 400;
    color: $black;
    margin-bottom: 20px;
  }

  @media #{$large} {
    padding: 40px 70px 50px 70px;
    margin-bottom: 100px;
  }

  .travel-scheme-card__optional-item {
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;
    background-color: $white;
  }
}

.wishlist-heading {
  padding-top: 25px;
  padding-bottom: 60px;

  h1 {
    margin-bottom: 20px;
  }
}

.wishlist-empty {
  background-image: url("../images/nature-white-bg--mobile.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 80px 0;
  margin-top: -120px;
  display: none;

  &.js-visible {
    display: block;
  }

  p {
    margin-bottom: 20px;
  }

  @media #{$medium} {
    margin-top: 0;
    padding: 140px 0;
    background-image: url("../images/nature-white-bg.jpg");

    p {
      margin-bottom: 30px;
    }
  }

  @media #{$larger} {
    padding: 220px 0;

    p {
      margin-bottom: 70px;
    }
  }
}

.wishlist-contact,
.contact-form {
  // background-color: $grayLight;
  background-color: #dcdcdc;
  padding: 20px;
  margin-bottom: 40px;
  position: relative;

  .wishlist-contact__disabled-content {
    display: none;
  }

  @media #{$medium} {
    padding: 45px 75px;
    margin-bottom: 100px;
  }
}

.wishlist-contact--disable {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(245, 245, 245, 0.95);
  }

  .wishlist-contact__disabled-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-weight: 300;
    font-size: 20px;
    padding: 45px 25px;
    text-align: center;
    line-height: 1.5em;

    @media #{$medium} {
      font-size: 23px;
      padding: 45px 75px;
    }

    @media #{$massive} {
      font-size: 28px;
    }
  }
}

.wishlist-contact__title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.excursion-title {
  margin-bottom: 10px;
}

.excursion-short-general-info {
  color: #8f9fb5;
  font-weight: 400;
  margin-bottom: 7px;
}

.excursion-description {
  font-size: 17px;
  line-height: 1.88em;
}

.excursion__price {
  font-weight: 400;
  margin-bottom: 20px;
}

.excursion-intro {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 85px;
  }
  @media #{$massive} {
    margin-bottom: 125px;
  }
}

.excursion-gallery,
.excursion-related {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 100px;
  }
  @media #{$massive} {
    margin-bottom: 155px;
  }
}

.excursion-related__title {
  margin-bottom: 25px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.excursion-overview {
  margin-top: 20px;
}

.excursion-overview__title {
  @media #{$medium} {
    float: left;
    display: inline-block;
  }
}

.excursion-overview__count {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
  float: right;

  @media #{$medium} {
    display: inline-block;
    float: right;
    margin-top: 7px;
  }

  @media #{$larger} {
    margin-top: 12px;
  }

  @media #{$massive} {
    margin-top: 22px;
  }
}

.contact__intro-text {
  margin-bottom: 40px;
  line-height: 1.88em;
}

.contact-map-title {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.contact-map__heading {
  margin-bottom: 40px;
}

.contact-map {
  position: relative;
}

.contact-map__content-container {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  pointer-events: none;
}

.contact-map__content {
  display: inline-block;
  padding: 70px 40px;
  background-color: $grayLight;
  z-index: 1;
  pointer-events: all;
}

.contact-map__title {
  margin-bottom: 27px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.contact-map__address {
  margin-bottom: 25px;
}

.contact-map__info {
  list-style: none;
  margin: 0 0 25px;

  li {
    white-space: nowrap;
    margin-bottom: 5px;
  }

  .icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 20px;
  }
}

.landing-page-section,
.default-section {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 75px;
  }

  @media #{$massive} {
    margin-bottom: 130px;
  }
}

.landing-page-intro {
}

.landing-page-intro__content {
  p {
    line-height: 1.88em;
    font-size: 17px;
  }

  p:first-of-type {
    font-weight: 500;
    font-size: 20px;
  }
}

.centered-images {
  display: block;
  position: relative;
  width: 100% !important;
  margin: 40px 0;

  @media #{$small} {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
  }

  img {
    margin: 0 !important;
    flex-grow: 1;
    width: 50% !important;
    height: auto !important;
    margin-bottom: 20px !important;
    display: block;

    @media #{$small} {
      padding: 0 10px;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    &:only-child {
      display: block;
      margin: 0 auto;
      max-width: 650px;
      width: 100% !important;
    }
  }

  @media #{$maxSmall} {
    img {
      width: 100% !important;
      display: block;
      margin-bottom: 20px;
    }
  }
}

.landing-page-related {
  padding: 20px 0;
  background-color: $grayLight;

  @media #{$medium} {
    padding: 35px 0;
    background-color: $grayLight;
  }

  @media #{$massive} {
    padding: 65px 0;
  }

  .grid__item:last-of-type {
    display: block;

    @media #{$small} {
      display: none;
    }

    @media #{$large} {
      display: block;
    }
  }
}

.travel-guide-grid {
  margin-bottom: 40px;
  @media #{$medium} {
    padding: 0 45px;
    margin-bottom: 80px;
  }
  @media #{$larger} {
    padding: 0;
    margin-bottom: 120px;
  }
}

.travel-guide-isotope {
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}

.travel-guide-category {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: $grayLight;
  height: 250px;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;

  a {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  &:hover {
    .travel-guide-category__image {
      transform: scale(1.03);
    }

    .travel-guide-category__icon {
      transform: translateX(-50%) translateY(-50%) scale(0.65);

      @media #{$larger} {
        transform: translateX(-50%) translateY(-50%) scale(1.05);
      }
    }
  }

  @media #{$large} {
    height: 250px;
  }

  @media #{$larger} {
    height: 400px;
  }
}

.travel-guide-category__icon {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0.6);
  z-index: -1;
  transition: all 0.2s ease-out;
  width: 140px;

  @media #{$larger} {
    transform: translateX(-50%) translateY(-50%);
    top: 40%;
  }
}

.travel-guide-category__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-out;
  z-index: -1;
}

.travel-guide-category__title--white {
  color: $white;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.7);

  &:hover {
    color: $white;
  }
}

.travel-guide-category--double-vertical {
  @media #{$large} {
    height: 520px;
  }

  @media #{$larger} {
    height: 820px;
  }
}

.travel-guide-category__title {
  font-size: 25px;
  text-decoration: none;
  padding: 20px;
  z-index: 1;
  line-height: 1.25em;
  margin-top: auto;
  @include display-font;
  font-weight: 700;

  @media #{$medium} {
    font-size: 30px;
  }

  @media #{$larger} {
    font-size: 40px;
  }
}

.about-intro {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 75px;
  }

  @media #{$massive} {
    margin-bottom: 100px;
  }
}

.about-team,
.contact-section {
  margin-top: 40px;
}

.about-canada__text {
  p {
    line-height: 30px;
  }
}

.about-canada__img {
  float: right;
}

.wishlist-items {
  margin-bottom: 60px;
}

.wishlist-item {
  padding: 18px 15px 20px 50px;
  border: 1px solid $grayMiddle;
  position: relative;
  min-height: 100px;
  margin-bottom: 10px;
  transition: all 0.3s ease-out;

  @media #{$medium} {
    padding: 18px 65px;
  }

  &.js-remove-intent {
    opacity: 0.3;
  }

  &.js-remove {
    transform: translateX(-40px);
    opacity: 0;
  }

  .checkbox-item {
    width: 20px;
    height: 20px;
    padding-left: 0;
    position: absolute;
    top: 17px;
    left: 13px;

    @media #{$medium} {
      top: 40px;
      left: 21px;
    }

    input,
    .checkmark {
      top: 0;
    }

    .checkmark {
      border: 1px solid $grayMiddle;
    }

    label {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      margin-bottom: 0;
      left: 0;
    }
  }
}

.wishlist-item__close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
  padding: 8px 9px 9px 10px;
  line-height: 1em;

  &:hover {
    background-color: $grayLight !important;
  }
}

.wishlist-item__content {
  display: flex;
  justify-content: space-between;

  & > div {
    margin: 0 10px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.wishlist-item__options {
  margin-top: 23px;

  @media #{$maxMedium} {
    display: block !important;
  }
}

.wishlist-item__options-empty {
  &.show-mobile {
    @media #{$maxMedium} {
      display: block !important;
    }
  }
}

.wishlist-item__option {
  background-color: $grayLight;
  padding: 10px 44px 10px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  position: relative;
  transition: all 0.3s ease-out;

  &.js-remove-intent {
    opacity: 0.3;
  }

  &.js-remove {
    transform: translateX(-20px);
    opacity: 0;
  }
}

.wishlist-item__option-close {
  position: absolute;
  right: 15px;
  opacity: 0.5;
  font-size: 15px;

  &:hover {
    opacity: 0.8;
  }
}

.wishlist-item__options-title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;

  @media #{$medium} {
    font-size: 17px;
  }
}

.wishlist-item__image {
  width: 99px;
}

.wishlist-item__main-identification {
  flex-grow: 1;
  margin: 3px 10px !important;
  max-width: calc(100% - 125px);

  @media #{$medium} {
    max-width: calc(100% - 335px);
  }

  @media #{$large} {
    max-width: calc(100% - 492px);
  }
}

.wishlist-item__link {
  a {
    display: block;
    color: $accent;
    font-weight: 700;
    line-height: 1.5em;
    margin-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover,
    &:focus,
    &:active {
      color: $accent;
    }
  }
}

.wishlist-item__general-info {
  color: $grayDark;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wishlist-item__categories {
  width: 160px;
  margin-top: 15px;
  margin-bottom: 0;
  @include clearfix;
  display: none;

  @media #{$large} {
    display: block;
  }

  .icon {
    text-align: center;
    padding: 0 6px 6px 6px;
    width: 33%;
    margin: 0;
    float: left;
    position: relative;
    height: 35px;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:nth-of-type(3n + 4) {
      clear: left;
    }
  }
}

.wishlist-item__options-toggle {
  background-color: $grayLight;
  color: $black;
  font-size: 17px;
  padding: 12px 26px;
  margin-top: 10px;
  min-width: 173px;

  @media #{$maxMedium} {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    color: $black;
  }

  &:hover {
    background-color: $grayMiddle;
  }

  &.js-active {
    background-color: $grayMiddle;
  }
}

.visual--wishlist-item {
  padding-top: 62.6%;
}

.wishlist-add-journey-cta {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 16px;
}

.price-popup__content {
  padding: 40px 20px;
  background-color: $white;
  max-width: 1040px;
  margin: 0 auto;

  @media #{$medium} {
    padding: 60px 20px;
  }
}

.price-popup__gallery {
  margin-top: 40px;
}

.infomoments-overview {
  margin-top: 20px;

  @media #{$medium} {
    margin-top: 40px;
  }
}

/* =============================================================================
   ZOHO FORM
   ========================================================================== */

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}

// popups

.popup {
  position: absolute;
  visibility: hidden;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  place-content: center;

  &.is-visible {
    visibility: visible;

    .popup__overlay,
    .popup__wrapper {
      opacity: 1;
      visibility: visible;
    }
  }
}

.popup__overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  background: hsla(0, 0%, 0%, 0.35);
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.popup__wrapper {
  position: fixed;
  bottom: $gutter;
  top: $gutter;
  right: $gutter;
  left: $gutter;
  z-index: 9999;
  max-width: 36em;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  overflow-y: auto;
  transition: visibility 0s linear 0.3s, opacity 0.3s;

  @media #{$small} {
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
}

.popup__content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding: 50px 30px;

  .button {
    width: 100%;
    text-align: center;
    border-radius: 6px;
  }

  @media #{$small} {
    padding: 75px 50px;
  }
}

.popup__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  color: #aaa;
  background: none;
  border: 0;

  &:hover {
    color: $accent;
  }
}
