
/*============================================================================
  TYPOGRAPHY
==============================================================================*/
@font-face {
  font-family: 'Electra';
  src: url('../fonts/ElectraLTStd-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/ElectraLTStd-Regular.otf')  format('opentype'),
  url('../fonts/ElectraLTStd-Regular.woff') format('woff'), url('../fonts/ElectraLTStd-Regular.ttf')  format('truetype'), url('../fonts/ElectraLTStd-Regular.svg#../fonts/ElectraLTStd-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?bh8l5k');
  src:  url('../fonts/icomoon.eot?bh8l5k#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?bh8l5k') format('truetype'),
  url('../fonts/icomoon.woff?bh8l5k') format('woff'),
  url('../fonts/icomoon.svg?bh8l5k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-winter:before {
  content: "\e905";
}
.icon-plus:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e917";
}
.icon-minus:before {
  content: "\e918";
}
.icon-car:before {
  content: "\e900";
}
.icon-luxury:before {
  content: "\e901";
}
.icon-train:before {
  content: "\e902";
}
.icon-cruise-ship:before {
  content: "\e903";
}
.icon-motorhome:before {
  content: "\e904";
}
.icon-heart-full:before {
  content: "\e948";
}
.icon-menu:before {
  content: "\e949";
}
.icon-arrow-right:before {
  content: "\e94a";
}
.icon-phone:before {
  content: "\e94b";
}
.icon-mail:before {
  content: "\e94c";
}
.icon-marker:before {
  content: "\e94d";
}
.icon-return:before {
  content: "\e94e";
}
.icon-checkmark:before {
  content: "\e94f";
}
.icon-twitter:before {
  content: "\e951";
}
.icon-facebook:before {
  content: "\e952";
}
.icon-instagram:before {
  content: "\e953";
}
.icon-arrow-up:before {
  content: "\e954";
}
.icon-heart:before {
  content: "\e955";
}
.icon-trees:before {
  content: "\e956";
}
.icon-plane:before {
  content: "\e957";
}
.icon-account:before {
  content: "\e958";
}
.icon-beach-chair:before {
  content: "\e959";
}
.icon-tent:before {
  content: "\e95a";
}



// Font Stacks
@mixin default-font($size: 16, $weight: 300, $lineHeight: 1.5em) {
  font-family: zeitung-micro, Helvetica, Arial, Verdana, sans-serif;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
  @include optimize-font;
}

@mixin display-font() {
  font-family: 'Electra', serif;
  font-weight: 400;
  @include optimize-font;
}


// Body

p {
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

strong {
  font-weight: 700;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit; font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: underline;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
  }

  &:focus, &:active {
    outline: 0;
  }

  &.tdn {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

a[href^=tel]{
  text-decoration:none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

a:not([href]) {
  color: $black; text-decoration: none;
  &:hover, &:focus {
  }
}

img {
  max-width: 100%; height: auto; display: inline-block; vertical-align: middle;
}


// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  @include optimize-font;
  color: $black;
  line-height: 1.2em;
  margin: 0 0 0.8em;
  font-weight: 400;

  a {
    text-decoration: none;
    outline: 0; font-weight: inherit;
    color: $accent;

    &:hover, &:focus {
      color: $accentHover;
    }
  }
}

h1, .h1 {
  font-size: 24px;

  @media #{$medium} {
    font-size: 28px;
  }

  @media #{$large} {
    font-size: 32px;
  }

  @media #{$massive} {
    font-size: 40px;
  }

  &.large {
    font-size: 24px;

    @media #{$medium} {
      font-size: 32px;
    }

    @media #{$large} {
      font-size: 36px;
    }

    @media #{$massive} {
      font-size: 50px;
    }
  }
}

h2, .h2 {
  font-size: 24px;

  @media #{$medium} {
    font-size: 28px;
  }

  @media #{$large} {
    font-size: 32px;
  }

  @media #{$massive} {
    font-size: 40px;
  }
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 16px, 22px);
}

h4, .h4 {
  @include fs(16);
}

h5, .h5 {  }

h6, .h6 {  }


// Lists

ul, ol {
  margin: 0 0 1.5em 1.3em;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #E3E3E3; padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0; border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled, nav ul {
  margin: 0; padding: 0; list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none; list-style-image: none;
    padding: 0; background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}


// Misc

hr {
  display: block; height: 1px;
  border: 0; border-top: 1px solid $gray; margin: 50px 0;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}

.small-text {
  font-size: 14px;
}
